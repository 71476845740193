import ahoy from 'ahoy.js'

ahoy.configure({
   visitsUrl: '/catcher/visits',
   eventsUrl: '/catcher/events',
})

window.toggle_user_sharing = function (state) {
   $.post('/profile/toggle_sharing', {
      enabled: state,
      authenticity_token: $('meta[name="csrf-token"]').attr('content'),
   })
}

window.editDisc = function (disc_key) {
   $.get(`/discs/${disc_key}/edit`, function (data) {
      $('#disc_form_holder').html(data)
      disc_form_setup()
   })
   .fail(function (data) {
      window.location = '/discs'
   })
}

window.resetBagForm = function () {
   $.get(`/discs/new_bag`, function (data) {
      $('#bag_form_holder').html(data)
   })
}

window.resetDiscForm = function () {
   if ($('#disc_disc_model_id').data('select2')) {
      $('#disc_disc_model_id').select2('destroy')
   }
   if ($('#disc_plastic_id').data('select2')) {
      $('#disc_plastic_id').select2('destroy')
   }
   $('#disc_color_button').spectrum('destroy')

   $.get(`/discs/new`, function (data) {
      $('#disc_form_holder').html(data)
      disc_form_setup()
   })
}

window.set_current_bag_checkbox = function () {
   $('#disc_bags input[data-name="' + $('#current_bag').text() + '"]').prop('checked', 'checked')
}

window.disc_form_setup = function () {
   $('.js-range-slider').ionRangeSlider({})

   // remove previously added first so we don't get multiple events
   $('#save-image-button').off('click')
   $('#save-image-button').on('click', function (e) {
      ahoy.track('saved-bag-image', {
         'bag-name': $('#current_bag').text(),
      })
   })

   $('#disc_color_button').spectrum({
      allowEmpty: true,
      preferredFormat: 'hex',
      showPalette: true,
      hideAfterPaletteSelect: true,
      clickoutFiresChange: true,
      palette: [
         ['white', '#bbb', '#999', '#555', 'black'],
         ['#00f9ff', '#15ff00', '#ff0', '#ff6bfa', '#fe8d00'],
         ['#00b8ff', '#d600ff', 'red'],
      ],
      showSelectionPalette: true,
      localStorageKey: 'spectrum.disc_form',
   })

   var clipboard = new ClipboardJS('.clippable')

   // copy share link
   clipboard.on('success', function (e) {
      ahoy.track('shared-bag', {
         'bag-name': $('#current_bag').text(),
         'bag-slug': e.text.split('/').slice(-1)[0],
      })

      $('.clippable img').toggleClass('hidden')
      setTimeout(function () {
         $('.clippable img').toggleClass('hidden')
      }, 2000)
   })

   // override the width:auto to use full column for all select2
   setTimeout(function () {
      $('.select2.select2-container.select2-container--default').attr('style', 'width: 100%')
   }, 500)

   $('#disc_disc_model_id').on('change', function () {
      var selected = $('#disc_disc_model_id option:selected')

      if (disc_model_add_custom_label == selected.text()) {
         document.getElementById('disc_model_label').classList.add('hidden')
         document.getElementById('manufacturer_label').classList.remove('hidden')
         document.getElementById('model_manual').classList.remove('hidden')
      } else {
         // only set this when overriding
         // document.querySelector('#disc_manufacturer').value = selected.data('manufacturer');
         // document.querySelector('#disc_model').value = selected.data('manufacturer');

         // reset plastic options with manufacturer list
         $('#disc_plastic_id').select2('destroy')
         $('#disc_plastic_id option').remove()

         var options = plastics_by_manufacturer[selected.data('manufacturerId')]
         if (typeof options === 'undefined') {
            options = [
               { id: '', text: 'Select Plastic' },
               { id: plastic_add_custom_label, text: plastic_add_custom_label },
            ]
         }

         $('#disc_plastic_id').select2({
            placeholder: 'Select Plastic',
            data: options,
         })

         // update flight number fields
         $('#disc_speed').val(selected.data('speed'))
         $('#disc_glide').val(selected.data('glide'))
         $('#disc_turn').val(selected.data('turn'))
         $('#disc_fade').val(selected.data('fade'))


      }
   })

   var disc_model_select = $('#disc_disc_model_id').select2({
      placeholder: 'Search for a disc...',
      // debug: <%= Rails.env.development? %>,
      theme: 'default',
      // data: [{"id": "1", "text": "one"}]
      // "selectionCssClass": "pt-1 pl-3"
   })

   // Plastic select2
   $('#disc_plastic_id').on('change', function () {
      var selected = $('#disc_plastic_id option:selected')

      if (add_custom_plastic_text == selected.text()) {
         document.querySelector('#disc_plastic_label').classList.add('hidden')
         document.querySelector('#disc_plastic_manual').disabled = false
         document.querySelector('#disc_plastic_manual_label').classList.remove('hidden')
      }
   })

   var selected_disc_model = $('#disc_disc_model_id option:selected')
   var selected_plastic = $('#disc_plastic_id option:selected')

   if (selected_disc_model.length > 0) {
      $('#disc_plastic_id option').remove()

      var options = plastics_by_manufacturer[selected_disc_model.data('manufacturerId')]
      if (typeof options === 'undefined') {
         options = [
            { id: '', text: 'Select Plastic' },
            { id: plastic_add_custom_label, text: plastic_add_custom_label },
         ]
      }

      $('#disc_plastic_id').select2({
         placeholder: 'Select Plastic',
         data: options,
      })

      if (selected_plastic.length > 0) {
         $('#disc_plastic_id').val(selected_plastic.val()) // Select the option with a value of '1'
         $('#disc_plastic_id').trigger('change')
      }
   } else {
      $('#disc_plastic_id').select2({
         placeholder: 'Select Plastic',
      })
   }

   if ('' === $('#disc_plastic_manual').val()) {
      $('#disc_plastic_label').removeClass('hidden')
      $('#disc_plastic_manual_label').addClass('hidden')
   } else {
      $('#disc_plastic_label').addClass('hidden')
      $('#disc_plastic_manual_label').removeClass('hidden')
   }

   // $("#disc_bag_keys option:contains('bagname')")

   // $('#disc_bag_keys').select2({
   //    placeholder: 'Type new bag name or select bags for this disc',
   //    tags: true,
   //    createTag: function (params) {
   //       var term = $.trim(params.term)

   //       if (term === '') {
   //          return null
   //       }

   //       return {
   //          id: `new----${term}`,
   //          text: term,
   //       }
   //    },
   // });
}
