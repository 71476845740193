import { Controller } from 'stimulus'
import _ from 'lodash'

export default class extends Controller {
   static targets = ['submit', 'clear']

   debouncedSubmit = _.debounce(this.submit, 600)
   is_dirty = false

   isFormChanged() {
      let changed = false

      this.scope.findAllElements('input[type=text]:not(.js-range-slider)').forEach(input => {
         if (input.value != '') {
            changed = true
         }
      })

      this.scope.findAllElements('.js-range-slider').forEach(slider => {
         const ion_slider_data = $(slider).data()

         if (ion_slider_data.from !== ion_slider_data.min || ion_slider_data.to !== ion_slider_data.max) {
            changed = true
         }
      })

      return changed
   }

   submit() {
      // We have caching for this page disabled via meta tag. This means, the back button will do a full reload instead of using the cache.
      // However, we still want to update the URL without making a full reload request. Basically, pushState to the URL in a way that doesn't mess with Turbolinks.
      const form_values = $(this.submitTarget).serialize()
      Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(`?${form_values}`, Turbolinks.uuid())

      // This will execute a remote ajax submit, only replacing the contents of the results list.
      this.submitTarget.requestSubmit()
   }

   onSearchKeyup() {
      if (!this.is_dirty) {
         if (this.hasClearTarget) {
            this.clearTarget.classList.remove('hidden')
         }
      }

      this.is_dirty = true
      this.debouncedSubmit()
   }

   connect() {
      const controller = this
      this.scope.findAllElements('.js-range-slider').forEach(slider => {
         $(slider).ionRangeSlider({
            onChange: function () {
               controller.onSearchKeyup()
            },
         })
      })

      this.is_dirty = this.isFormChanged()
      if (this.is_dirty) {
         if (this.hasClearTarget) {
            this.clearTarget.classList.remove('hidden')
         }
      }
      // this.submitTarget.requestSubmit()
   }

   clearFilters() {
      this.scope.findAllElements('input[type=text]:not(.js-range-slider)').forEach(input => {
         $(input).val('')
      })

      this.scope.findAllElements('select').forEach(input => {
         $(input).val('')
      })

      this.scope.findAllElements('.js-range-slider').forEach(slider => {
         const ion_slider = $(slider).data('ionRangeSlider')
         ion_slider.update({
            from: $(slider).data('min'),
            to: $(slider).data('max'),
         })
      })

      this.is_dirty = false

      if (this.hasClearTarget) {
         this.clearTarget.classList.add('hidden')
      }

      this.debouncedSubmit()
   }

   teardown() {
      this.scope.findAllElements('.js-range-slider').forEach(slider => {
         $(slider).data('ionRangeSlider').destroy()
      })
   }
}
