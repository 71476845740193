import { Controller } from 'stimulus'
import ahoy from 'ahoy.js'

export default class extends Controller {
   connect() {
      if (this.context.element.dataset.trackEventName) {
         const event_name = this.context.element.dataset.trackEventName
         const properties = this._getTrackingProperties(this.context.element)

         this._saveTrackingData(event_name, properties)
      }
   }

   trackEvent(event) {
      const event_name = event.target.dataset.trackEventName
      const properties = this._getTrackingProperties(event.target)

      if (event.target.value) {
         Object.assign(properties, { value: event.target.value })
      }

      this._saveTrackingData(event_name, properties)
   }

   _getTrackingProperties(elem) {
      return elem.dataset.trackProperties ? JSON.parse(elem.dataset.trackProperties) : {}
   }

   _saveTrackingData(event_name, properties = {}) {
      gtag('event', event_name)
      ahoy.track(event_name, properties)
   }
}
